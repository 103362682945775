
import React from 'react';
import {
	match,
	withRouter
} from 'react-router-dom';
import * as H from 'history';

import card_BigChineseWall2  from '../../static/pics/BigChineseWall_vert22.gif';
import card_NewYork2  from '../../static/pics/NewYork_vert22.gif';
import card_Shanhai2  from '../../static/pics/Shanhai_vert22.gif';
import usdt_icon from '../../static/pics/coins/usdt.svg';

type MainPageProps = {
	store                 : any,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type MainPageState = {
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store                 : any;

	render() {

		return (
			<main className="s-main">
				<div className="mt-4">
					<div className="divider right short-20"> </div>
					<div className="container">
					<div className="c-header">
						<div className="h3">Red Envelop</div>
					</div>
					<div className="lp-filter">
						<div className="row">
						<div className="col col-12 col-md-auto">
							<div>
								<p>Combine ancient Chinese traditions with the latest NFT craze using our Protocol!</p>
								<p>BUY your own festive gift «wrapped NFT Red Envelop» and send it to your loved ones, relatives and friends.</p>
							</div>
						</div>
						{/* <div className="col col-5 col-md-auto">
							<div className="lp-filter__sort">
								<label className="input-label">Sort by</label>
								{ this.getSortBlock() }
							</div>
						</div> */}
						</div>
					</div>
					<div className="lp-list">
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										Red Envelop Regular
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src={ card_BigChineseWall2 } alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 10 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" rel="noreferrer" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								<br />
								<p>Collateral with equivelent of 10 USDT with total price of 12.5 USDT. Unwrap possible after February 1st 2022 at 00:01 UTC+8.</p>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										Red Envelop Special
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src={ card_NewYork2 } alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 20 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" rel="noreferrer" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								<br />
								<p>Collateral with equivelent of 20 USDT with total price of 23.2 USDT. Unwrap possible after February 1st 2022 at 00:01 UTC+8.</p>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										Red Envelop Super
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src={ card_Shanhai2 } alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 50 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" rel="noreferrer" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								<br />
								<p>Collateral with equivelent of 50 USDT with total price of 55 USDT. Unwrap possible after February 1st 2022 at 00:01 UTC+8.</p>
								</div>
							</div>

						</div>
						<div className="c-row">
							<div className="c-p info-steps">
								<div className="info-step info-step-1">
									<p>1. Buy wNFT from Red ENVELOP Collection</p>
								</div>
								<div className="info-step info-step-2">
									<p>2. Upgrade - add collateral OR just skip this step</p>
									<a className="btn btn-lg" target="_blank" rel="noreferrer" href="https://app.envelop.is/">Upgrade</a>
								</div>
								<div className="info-step info-step-3">
									<p>3. Send your wNFT to any place</p>
									<a className="btn btn-lg" target="_blank" rel="noreferrer" href="https://app.envelop.is/">Send</a>
								</div>
							</div>
						</div>
						<div className="c-row">
							<div className="c-p">
								<p>Disclaimer: Here you can buy three types of Red Envelopes. Each type contains collateral with equivelent of 10, 20 and 50 USDT respectively. Unwrap possible after February 1st 2022 at 00:01 UTC+8.</p>
							</div>
						</div>
				</div>
				</div>
				</div>

			</main>
		)
	}
}

export default withRouter(MainPage);