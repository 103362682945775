
import icon_logo from '../../static/pics/logo.svg';
import youtube_logo      from '../../static/pics/socials/youtube.svg';
import in_logo      from '../../static/pics/socials/in.svg';
import github_logo      from '../../static/pics/socials/github.svg';
import telegram_logo      from '../../static/pics/socials/telegram.svg';
import cntelegram_logo      from '../../static/pics/socials/cn-telegram.svg';
import twitter_logo      from '../../static/pics/socials/twitter.svg';
import m_logo      from '../../static/pics/socials/m.svg';

function Footer() {
	return (
		<footer className="s-footer">
			<div className="container">
				<div className="row">

					<div className="col-4 col-lg-3 order-1">
						<div className="s-footer__logo">
							<img src={ icon_logo } alt="" />
						</div>
					</div>

					<div className="col-8 col-lg-3 order-2">
						<ul className="socials d-lg-flex">
							<li><a href="https://www.youtube.com/c/ENVELOP" target="_blank" rel="noreferrer"><img src={ youtube_logo } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
							<li><a href="https://www.linkedin.com/company/niftsy" target="_blank" rel="noreferrer"><img src={ in_logo } alt="NIFTSY is token" /></a></li>
							<li><a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noreferrer"><img src={ github_logo } alt="Github of our NFT project" /></a></li>
							<li><a href="https://t.me/envelop_en" target="_blank" rel="noreferrer"><img src={ telegram_logo } alt="ENVELOP telegram group" /></a></li>
							<li><a href="https://t.me/envelop_cn" target="_blank" rel="noreferrer"><img src={ cntelegram_logo } alt="ENVELOP telegram group (China)" /></a></li>
							<li><a href="https://twitter.com/Envelop_project" target="_blank" rel="noreferrer"><img src={ twitter_logo } alt="Our twitter" /></a></li>
							<li><a href="https://envelop.medium.com/" target="_blank" rel="noreferrer"><img src={ m_logo } alt="Blog about Web 3.0" /></a></li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;